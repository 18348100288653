import { Chip } from '@mui/material';
import { AuthContext } from 'providers/authProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import { isRegisteredImporter } from 'services/auth';

import { BackButton, Loading, NOTIFICATION_TYPE, Notification, Switch } from 'components';
import { useGetDocumentCategories } from 'features/documents/useGetDocumentsCategories';
import { useGetVehicleDocuments } from 'features/documents/useGetVehicleDocuments';
import { useGetPhotoCategories } from 'features/photos/useGetPhotoCategories';
import { useGetVehiclePhotos } from 'features/photos/useGetVehiclePhotos';
import { useGetVehicleDetail } from 'features/vehicles/hooks/useGetVehicleDetail';
import { useToggleNHTSAReady } from 'features/vehicles/hooks/useToggleNHTSAReady';
import { useGetVehicleBrokerSubmission } from 'features/vehicles/hooks/useGetVehicleBrokerSubmission';

import StatementConformity from '../VehicleForm/StatementConformity';
import DocumentsColumn from './DocumentsColumn/DocumentsColumn';
import PhotosColumn from './PhotosColumn/PhotosColumn';
import ProfileColumn from './ProfileColumn';
import RecallsColumn from './RecallsColumn';
import TimelineColumn from './TimelineColumn';
import './style.scss';

const VehicleProfile = () => {
  const { id } = useParams();
  const { user } = React.useContext(AuthContext);

  const [recallCheckedAll, setRecallCheckedAll] = React.useState(false);

  const {
    data: vehicle,
    isLoading: isLoadingVehicle,
    isFetching: isFetchingVehicle,
  } = useGetVehicleDetail(id);
  const { data: submissions = [] } = useGetVehicleBrokerSubmission(id);
  const { data: documents = [] } = useGetVehicleDocuments(id);
  const { data: docCategories = [] } = useGetDocumentCategories(id);
  const { data: photos = [] } = useGetVehiclePhotos(id);
  const { data: photoCategories = [] } = useGetPhotoCategories(id);
  const { mutateAsync: toggleNHTSAReady, isLoading: isTogglingNHTSAReady } =
    useToggleNHTSAReady(id);

  const canCreateNHTSA = React.useMemo(() => {
    if (!recallCheckedAll) {
      return false;
    }

    const requiredDocCategories = docCategories.filter((c) => c.isNHTSARequired && !c.canDelete);
    const uploadedDocs = documents.filter(
      (d) => d.category?.isNHTSARequired && !d.canDelete && d.file
    );

    if (uploadedDocs.length < requiredDocCategories.length) {
      return false;
    }

    const requiredPhotoCategories = photoCategories.filter((c) => c.isRequired && !c.canDelete);
    const uploadedPhotos = photos.filter((p) => p.category.isRequired && !p.canDelete && p.url);

    if (uploadedPhotos.length < requiredPhotoCategories.length) {
      return false;
    }

    return true;
  }, [docCategories, documents, photoCategories, photos, recallCheckedAll]);

  const handleChangeNhtsaReady = async () => {
    if (!isRegisteredImporter(user)) {
      return Notification(
        NOTIFICATION_TYPE.DANGER,
        'Only registered importers can use this feature'
      );
    }

    try {
      await toggleNHTSAReady(vehicle.id);
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    }
  };

  return (
    <div>
      {(isLoadingVehicle) && <Loading />}
      {!isLoadingVehicle && (
        <>
          <div className="mb-4 vehicle-detail-navigation">
            <BackButton />
            <Chip className="vehicle-detail-vin" variant="outlined" label={vehicle.vin} />
          </div>
          <Switch
            className="mt-2"
            checked={vehicle.nhtsaReady}
            onChange={handleChangeNhtsaReady}
            disabled={
              !canCreateNHTSA || isTogglingNHTSAReady || isLoadingVehicle || isFetchingVehicle
            }
            label="NHTSA Ready"
            data-testid="nhtsa-switch"
          />
          <div className="mt-2">
            <StatementConformity vehicle={vehicle} />
            <hr />
          </div>

          <div className="vehicle-columns-container">
            <DocumentsColumn
              vehicle={vehicle}
              documents={documents}
              categories={docCategories}
              className="vehicle-documents-column"
            />
            <PhotosColumn
              vehicle={vehicle}
              photos={photos}
              categories={photoCategories}
              className="vehicle-photos-column"
            />
            <RecallsColumn
              vehicle={vehicle}
              setRecallCheckedAll={setRecallCheckedAll}
              className="vehicle-recalls-column"
            />
            <TimelineColumn
              vehicle={vehicle}
              submissions={submissions}
              className="vehicle-timeline-column"
            />
            <ProfileColumn vehicle={vehicle} className="vehicle-profile-column" />
          </div>
        </>
      )}
    </div>
  );
};

export default VehicleProfile;
