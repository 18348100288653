import { useQuery } from '@tanstack/react-query';
import { BrokerAPI } from 'api';

export function useCheckEntryNumber(ids, multi, { onError } = {}, isResubmitActive) {
  return useQuery({
    queryKey: ['check-entry-number', { ids, multi, isResubmitActive }],
    queryFn: () =>
      BrokerAPI.checkEntryNumber({ ids, multi, isResubmitActive })
        .then((res) => res.data)
        .catch((error) => {
          typeof onError === 'function' && onError(error);
        }),
    enabled: false,
  });
}
