import React from 'react';
import { Edit2 } from 'react-feather';
import { formatDate } from 'utilities';

import { IconButton, VehicleProfileColumn } from 'components';

const TimelineColumn = ({ vehicle, submissions, className }) => {
  const statusesWithDates = [
    { id: 1, name: 'Vehicle added', date: vehicle.entryDate },
    { id: 2, name: 'Sent to broker', date: submissions.map((s) => s.createdAt).join('\n') },
    { id: 3, name: 'Import approved', date: '' },
    { id: 4, name: 'Carrier Pickup', date: '' },
    { id: 5, name: 'Rejected', date: '' },
    { id: 6, name: 'Border Crossed', date: '' },
    { id: 7, name: 'Delivered', date: '' },
    { id: 8, name: 'RI Label Created', date: '' },
    { id: 9, name: 'DOT Photos Completed', date: '' },
    { id: 10, name: 'Recall Checked', date: '' },
    { id: 11, name: 'Sent to DOT', date: '' },
    { id: 12, name: 'Vehicle Released', date: '' },
    { id: 13, name: 'Bond Released', date: '' },
  ];
  return (
    <VehicleProfileColumn title="Vehicle timeline" className={className}>
      <div className="table-responsive">
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Date</th>
              <th scope="col" style={{ textAlign: 'center' }}>
                {' '}
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            <>
              {statusesWithDates.map((status) => (
                <tr key={status.id}>
                  <td>{status.name}</td>
                  <td>
                    {status.name === 'Sent to broker' ? (
                     status.date.split('\n').map((date, idx) => (
                      <div key={idx}>{formatDate(date, 'DD/MM/YYYY kk:mm:ss')}</div>
                      ))
                      ) : (
                      formatDate(status.date, 'DD/MM/YYYY kk:mm:ss')
                      )}
                  </td>
                  <td>
                    {status.name !== 'Vehicle added' && status.name !== 'Sent to broker' && (
                     <IconButton icon={<Edit2 />} />
                    )}
                  </td>
                </tr>
              ))}
            </>
          </tbody>
        </table>
      </div>
    </VehicleProfileColumn>
  );
};

export default TimelineColumn;
