import {
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import { AuthContext } from 'providers/authProvider';
import React from 'react';

import { Button, Dialog, NOTIFICATION_TYPE, Notification } from 'components';
import { useSearchVehicles } from 'features/vehicles/hooks/useSearchVehicles';

import { useCheckEntryNumber } from '../hooks/useCheckEntryNumber';
import { useSubmitToBroker } from '../hooks/useSubmitToBroker';

export function SubmitToBrokerModal({
  vehicleListToSubmit,
  setVehicleListToSubmit,
  show,
  isResubmitActive,
  handleClose,
  onSuccess,
}) {
  const { user } = React.useContext(AuthContext);

  const { refetch } = useSearchVehicles();
  const [submitType, setSubmitType] = React.useState('single');
  const [consignee, setConsignee] = React.useState('ri');
  const [importer, setImporter] = React.useState('ri');
  const [error, setError] = React.useState(null);

  const {
    data: entryNumberList,
    refetch: checkEntryNumber,
    isLoading: isLoadingEntryNumberList,
    isFetching: isFetchingEntryNumberList,
    isError,
  } = useCheckEntryNumber(
    vehicleListToSubmit.map((v) => v.id),
    submitType === 'multi',
    {
      onError: (error) => setError(error.message),
    },
    isResubmitActive
  );

  const { mutate: submitToBroker, isLoading: isSubmitting } = useSubmitToBroker({
    onSuccess: (data) => {
      isResubmitActive = false;
      const updatedVehicles = data.updatedVehicles;
      setVehicleListToSubmit(updatedVehicles);
      refetch();
      Notification(NOTIFICATION_TYPE.SUCCESS, 'Submit to Broker is successfully.');
      handleClose();
      typeof onSuccess === 'function' && onSuccess();
    },
    onError: (error) => {
      Notification(NOTIFICATION_TYPE.DANGER, error.message);
    },
  });

  React.useEffect(() => {
    if (show && vehicleListToSubmit && vehicleListToSubmit.length > 0) {
      if (vehicleListToSubmit.length === 1) {
        setSubmitType('single');
      }
    }
  }, [show, vehicleListToSubmit]);

  React.useEffect(() => {
    if (show && !isResubmitActive) {
      checkEntryNumber();
    }
  }, [checkEntryNumber, show, submitType]);

  const onSubmit = async () => {
    // Check range number settings
    const errors = checkRangeSettings(user.rangeSettings);

    if (errors.length > 0) {
      errors.forEach((error) => {
        Notification(NOTIFICATION_TYPE.DANGER, error);
      });

      return;
    }

    const formData = new FormData();

    vehicleListToSubmit.forEach((v) => {
      formData.append('ids[]', v.id);
    });
    formData.append('submitDate', moment.utc().toISOString());
    
    if (isResubmitActive) {
      await checkEntryNumber();
      formData.append('isResubmitActive', 'true');
    }

    submitToBroker(formData);
  };

  return (
    <Dialog open={show} onClose={handleClose} title={isResubmitActive ? "Vehicles To Be Resubmitted" : "Vehicles To Be Submitted"}>
      <DialogContent sx={{ py: 7.5 }}>
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col">Vin</th>
              <th scope="col">Entry Number</th>
              <th scope="col">Make</th>
              <th scope="col">Model</th>
              <th scope="col">Year</th>
            </tr>
          </thead>
          {vehicleListToSubmit ? (
            <tbody>
              {vehicleListToSubmit.map((vehicle, index) => {
                return (
                  <tr key={index}>
                    <td>{vehicle.vin}</td>
                    <td>
                      {!isResubmitActive && (isLoadingEntryNumberList || isFetchingEntryNumberList) ? (
                        <Skeleton
                          animation="pulse"
                          sx={{ bgcolor: (theme) => theme.colors.grey_2, width: '13ch' }}
                        />
                      ) : isResubmitActive && vehicle.entryNumber ? (
                        vehicle.entryNumber
                      ) : !isError && entryNumberList ? (
                        entryNumberList[index]
                      ) : (
                        'Failed to generate'
                      )}
                    </td>
                    <td>{vehicle.make}</td>
                    <td>{vehicle.model}</td>
                    <td>{vehicle.year}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody></tbody>
          )}
        </table>
        <hr />
        <div className="px-3 d-flex justify-content-between">
          <div>
            <FormControl>
              <FormLabel id="signature" className="text-white">
                Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="signature"
                value={submitType}
                onChange={(e) => setSubmitType(e.target.value)}
              >
                <FormControlLabel value="single" control={<Radio />} label="Single" />
                <FormControlLabel
                  value="multi"
                  control={<Radio />}
                  label="Multi"
                  disabled={vehicleListToSubmit?.length === 1}
                />
              </RadioGroup>
            </FormControl>
            {isError && <p className="mb-0 text-red">{error}</p>}
          </div>
          <div>
            <FormControl>
              <FormLabel id="consignee" className="text-white">
                Consignee
              </FormLabel>
              <RadioGroup
                aria-labelledby="consignee"
                value={consignee}
                onChange={(e) => setConsignee(e.target.value)}
              >
                <FormControlLabel label="RI" value="ri" control={<Radio />} />
                <FormControlLabel label="Buyer" value="buyer" control={<Radio />} />
                <FormControlLabel label="Seller" value="seller" control={<Radio />} />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <FormControl>
              <FormLabel id="importer" className="text-white">
                Importer Record
              </FormLabel>
              <RadioGroup
                aria-labelledby="importer"
                value={importer}
                onChange={(e) => setImporter(e.target.value)}
              >
                <FormControlLabel label="RI" value="ri" control={<Radio />} />
                <FormControlLabel label="Buyer" value="buyer" control={<Radio />} />
                <FormControlLabel label="Seller" value="seller" control={<Radio />} />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          type="button"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isResubmitActive && (isError || isLoadingEntryNumberList || isFetchingEntryNumberList)}
        >
          {isResubmitActive ? "Resubmit" : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function checkRangeSettings(rangeSettings) {
  const errors = [];

  if (!rangeSettings) {
    errors.push(
      'Please configure range settings for Customs Broker, DOT Bond Provider and RI Warranty Provider'
    );
  } else {
    const { customsLow, customsHigh, bondLow, bondHigh, warrantyLow, warrantyHigh } = rangeSettings;

    if (customsLow === 0 && customsHigh === 0) {
      errors.push('No Range Selection for Customs Broker');
    }

    if (bondLow === 0 && bondHigh === 0) {
      errors.push('No Range Selection for DOT Bond Provider');
    }

    if (warrantyLow === 0 && warrantyHigh === 0) {
      errors.push('No Range Selection for RI Warranty Provider');
    }
  }

  return errors;
}
