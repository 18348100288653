import { DeleteOutline as DeleteOutlineIcon, Edit as EditIcon, Archive as ArchiveIcon } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { VehicleApi } from 'api';
import { ROUTES } from 'constants/routes';
import { AuthContext } from 'providers/authProvider';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { hasPermission } from 'services/auth';

import { ConfirmModal, MoreMenu, NOTIFICATION_TYPE, Notification } from 'components';

import './styles.scss';
import { VEHICLE_STATUSES } from 'constants/vehicle';

const Actions = {
  REMOVE: 'delete',
  MOVE_TO_VAULTED: 'vaulted',
};

const actionsMap = {
  [Actions.REMOVE]: {
    apiCall: (id) => VehicleApi.delete(id),
    confirmationTitle: 'Delete Vehicle',
    confirmationMessage: 'Are you sure you want to delete this vehicle?',
    successMessage: 'Vehicle deleted successfully.',
  },
  [Actions.MOVE_TO_VAULTED]: {
    apiCall: (id) => VehicleApi.update(id, { status: VEHICLE_STATUSES[5] }),
    confirmationTitle: 'Move to Vaulted',
    confirmationMessage: 'Are you sure you want to move this vehicle to Vaulted?',
    successMessage: 'Vehicle has been successfully updated to the Vaulted status.',
  },
};

const VehicleTableActions = ({ vehicle, refresh }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const { user } = useContext(AuthContext);

  const handleShowConfirm = (action) => {
    setSelectedAction(action);
    setShowConfirm(true);
  };

  const handleConfirm = async () => {
    if (!selectedAction || !actionsMap[selectedAction]) return;

    setLoading(true);

    try {
      await actionsMap[selectedAction].apiCall(vehicle.id);
      Notification(NOTIFICATION_TYPE.SUCCESS, actionsMap[selectedAction].successMessage);
      if (refresh) refresh();
    } catch (err) {
      Notification(NOTIFICATION_TYPE.DANGER, err.message);
    } finally {
      setLoading(false);
      setAnchorEl(null);
    }

  };

  return (
    <>
      <MoreMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
        <MenuItem
          className="edit-vehicle"
          disabled={!hasPermission(user, { resource: 'vehicle', action: 'update' })}
        >
          <Link
            to={ROUTES.MAIN.VEHICLES.EDIT.replace(':id', vehicle.id)}
            style={{ textDecoration: 'none' }}
          >
            <EditIcon sx={{ mr: '1rem' }} />
            Edit
          </Link>
        </MenuItem>
        <MenuItem
          onClick={() => handleShowConfirm(Actions.MOVE_TO_VAULTED)}
          disabled={
            !hasPermission(user, { resource: 'vehicle', action: 'update' })
            || loading
            || [VEHICLE_STATUSES[0], VEHICLE_STATUSES[1], VEHICLE_STATUSES[5]].includes(vehicle.status)
          }
        >
          <ArchiveIcon sx={{ mr: '1rem' }} />
          Move to Vaulted
        </MenuItem>
        <MenuItem
          onClick={() => handleShowConfirm(Actions.REMOVE)}
          disabled={!hasPermission(user, { resource: 'vehicle', action: 'delete' }) || loading}
          data-testid="delete-btn"
        >
          <DeleteOutlineIcon sx={{ mr: '1rem' }} />
          Delete
        </MenuItem>
      </MoreMenu>

      {selectedAction && (
        <ConfirmModal
          show={showConfirm}
          handleClose={() => setShowConfirm(false)}
          title={actionsMap[selectedAction].confirmationTitle}
          content={actionsMap[selectedAction].confirmationMessage}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};

export default VehicleTableActions;
