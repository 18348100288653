import { DialogActions, DialogContent, Typography } from '@mui/material';
import { Button, Dialog } from 'components';
import React from 'react';

export function ResubmitToBrokerModal({ show, handleClose, handleConfirmResubmission }) {
  return (
    <Dialog open={show} onClose={handleClose} title="Resubmission to Customs Broker">
      <DialogContent sx={{ py: 7.5 }}>

        <Typography variant="h6" gutterBottom>
          By selecting the Re-submit to Customs Broker option, you are initiating the following process:
        </Typography>

        <Typography variant="body1" component="ul" gutterBottom>
          <li>The existing customs entry number attached to this vehicle will be removed and marked as expired.</li>
          <li>A new customs entry number will be generated using today’s date, and this new entry will be submitted to the
            customs broker.
          </li>
          <li>The vehicle will retain its current status until the new customs entry is processed and approved.</li>
        </Typography>

        <Typography variant="h6" gutterBottom>
          <strong>Important Note:</strong>
        </Typography>

        <Typography variant="body1" component="ul" gutterBottom>
          <li>The previous customs entry will be permanently cancelled, and it cannot be recovered.</li>
          <li>
            You must ensure that all mechanical or recall issues are fully resolved before initiating this resubmission
            to avoid further delays in compliance.
          </li>
        </Typography>

        <Typography variant="h6" gutterBottom>
          <strong>Confirmation Required:</strong>
        </Typography>

        <Typography variant="body1" gutterBottom>
          Do you wish to proceed with the resubmission to the customs broker?
        </Typography>

      </DialogContent>

      <DialogActions>
        <Button class="custom-btn cancel-btn" type="button" onClick={() => handleClose(false)}>
          Cancel
        </Button>
        <Button type="button" onClick={() => handleConfirmResubmission(true)} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
